import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
    textAlign: 'center',
  },
  header: {
    paddingBottom: '1rem',
  }
}));

const GenericNotFound = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="h2" className={classes.header}>
        404 Page not found!
      </Typography>
      <Typography variant="h5">
        Return to the <a href="https://twitter.chessvision.ai">homepage</a>
      </Typography>
    </div>
  )

}

export default GenericNotFound;
