import gray from '@material-ui/core/colors/grey';
import deepOrange from '@material-ui/core/colors/deepOrange';

const settings = {
  contactEmail: 'hello@chessvision.ai',
  products: [
    { url: "https://ebook.chessvision.ai", title: "eBook Reader", external: true, },
    { url: "https://apps.apple.com/us/app/id1574933453", title: "iOS App", external: true, },
    { url: "https://play.google.com/store/apps/details?id=ai.chessvision.scanner", title: "Android App", external: true, },
    { url: "https://chrome.google.com/webstore/detail/chessvisionai-for-chrome/johejpedmdkeiffkdaodgoipdjodhlld", title: "Chrome Extension", external: true, },
    { url: "https://addons.mozilla.org/firefox/addon/chessvision-ai-for-firefox/", title: "Firefox Add-on", external: true, },
    { url: "https://my.chessvision.ai", title: "Video App", external: true, },
  ],
  videoAppUrl: 'https://my.chessvision.ai',
  discordInvite: 'https://discord.com/invite/zkcBPhWhme',
  theme: {
    primaryColor:{
      main: '#1d0954',
      light: '#2E186D',
    },
    secondaryColor: deepOrange,
    backgroundColor: gray,
  },
  maxMobileWidth: 1160,
}

export default settings;
