import { Routes, Route, Navigate } from "react-router-dom";

import Home from './components/Home';
import Bar from './components/Bar';
import Footer from './components/Footer';
import GenericNotFound from './components/404';

import settings from './settings';

const { products } = settings;

const CustomBar = () => (
  <Bar
    links={products.map(({ url, title }) => ({ url, title, external: true}))}
  />
);

function App() {
  return (
    <div>
      <CustomBar />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/404" exact element={<GenericNotFound />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
