import Chess from 'chess.js';

export const validateFen = (fen) => {
  fen = fen.trim();
  fen = fen.split('_').join(' '); // to accept '_' separator from input
  const parts = fen.split(' ');
  const defaults = ['w', 'KQkq', '-', '0', '1'];
  while (parts.length < 6) {
    parts.push(defaults[parts.length - 1]);
  }
  fen = parts.join(' ');
  const board = new Chess();
  const isValid = board.load(fen);
  return isValid ? board.fen() : null;
}
