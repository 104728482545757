import React from 'react';

import Chess from 'chess.js';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Snackbar from '@material-ui/core/Snackbar';

import { validateFen } from '../utils';
import settings from '../settings';

const styles = theme => ({
  container: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
    textAlign: 'center',
    maxWidth: 1200,
    margin: '0 auto',
  },
  header: {
    margin: '0 auto',
    maxWidth: 600,
    textAlign: 'center',
    marginTop: 32,
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [`@media (max-width: ${settings.maxMobileWidth}px)`]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    marginTop: 64,
  },
  inputs: {
    flex: 1,
    padding: 15,
    [`@media (max-width: ${settings.maxMobileWidth}px)`]: {
      width: '100%',
      maxWidth: 600,
    },
  },
  fenInputWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  selectInputs: {
    marginTop: 32,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  formControl: {
    width: 120,
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderColor: "blue"
    }
  },
  selectLabel: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: 5,
    paddingRight: 5,
  },
  selectInput: {
    "&:before": {
      borderColor: "red"
    },
  },
  linkInputWrapper: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  imageWrapper: {
    flex: 1,
    [`@media (max-width: ${settings.maxMobileWidth}px)`]: {
      marginTop: 32,
    },
  },
});

const STARTING_FEN = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';

const getImageUrl = (fen, turn, pov) => {
  // we want to have the url on the exact same domain as the current page because
  // only then downloading file triggers "save file" in a browser
  let imageUrl;
  if (window.location.href.includes('www.')) {
    imageUrl = `https://www.fen2image.chessvision.ai/${fen}`;
  } else {
    imageUrl = `https://fen2image.chessvision.ai/${fen}`;
  }
  if (turn !== null) {
    imageUrl += `?turn=${turn}`;
  }
  if (pov !== 'white') {
    if (!imageUrl.includes('?')) {
      imageUrl += '?';
    } else {
      imageUrl += '&';
    }
    imageUrl += `pov=${pov}`;
  }
  return imageUrl.split(' ').join('_');
}

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fen: STARTING_FEN,
      turn: 'white',
      pov: 'white',
      fenInput: '',
      snackbarOpen: false,
    };
  }

  setFen = (fen) => {
    const parts = fen.split(' ');
    let newTurn = this.state.turn;
    if (parts.length > 1) {
      if (parts[1] === 'w') {
        newTurn = 'white';
      } else if (parts[1] === 'b') {
        newTurn = 'black';
      }
    }
    this.game = new Chess(fen);
    this.setState({
      fen: this.game.fen(),
      fenFromInput: '',
      turn: newTurn,
    });
  }

  copyLinkToClipboard = (link) => {
    const temp = document.createElement('textarea');
    temp.value = link;
    document.body.appendChild(temp);
    temp.select();
    document.execCommand('copy');
    document.body.removeChild(temp);
    this.setState({
      snackbarOpen: true,
    });
  }

  render() {
    const { classes } = this.props;
    const { fen, turn, pov, fenInput } = this.state;
    const { snackbarOpen } = this.state;

    const fenFromInput = validateFen(fenInput);
    const imageUrl = getImageUrl(fen, turn, pov);
    return (
      <Container className={classes.container}>
        <div className={classes.header}>
          <Typography variant="h4" style={{ fontWeight: 800 }}>
            Generate images from FEN easily!
          </Typography>
        </div>
        <div className={classes.content}>
          <Paper className={classes.inputs}>
            <div className={classes.fenInputWrapper}>
              <TextField
                label="FEN"
                size="small"
                variant="outlined"
                value={fenInput || fen}
                onChange={(e) => { this.setState({ fenInput: e.target.value })}}
                style={{ flex: 1 }}
              />
              <Button
                onClick={() => { this.setFen(fenFromInput) }}
                size="small"
                variant="contained"
                disabled={fenFromInput === null || fenFromInput === fen}
                style={{ marginLeft: '1rem', marginRight: '0.5rem', textTransform: 'none' }}
              >Set FEN</Button>
            </div>
            <div className={classes.selectInputs}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel
                  shrink
                  id="select-turn-label"
                  className={classes.selectLabel}
                >Turn</InputLabel>
                <Select
                  labelId="select-turn-label"
                  label="Turn"
                  value={turn}
                  onChange={(e) => { this.setState({ turn: e.target.value })}}
                  displayEmpty
                  className={classes.selectInput}
                >
                  <MenuItem value={null}>None</MenuItem>
                  <MenuItem value="white">White</MenuItem>
                  <MenuItem value="black">Black</MenuItem>
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ marginLeft: 32 }}
              >
                <InputLabel
                  shrink
                  id="select-pov-label"
                  className={classes.selectLabel}
                >Point of view</InputLabel>
                <Select
                  labelId="select-pov-label"
                  label="Point of view"
                  value={pov}
                  variant="outlined"
                  onChange={(e) => { this.setState({ pov: e.target.value })}}
                  className={classes.selectInput}
                >
                  <MenuItem value="white">White</MenuItem>
                  <MenuItem value="black">Black</MenuItem>
                </Select>
              </FormControl>
            </div>
            <Button
              variant="contained"
              color="secondary"
              href={imageUrl}
              download
              style={{ marginTop: 32 }}
            >Download Image</Button>
            <div className={classes.linkInputWrapper}>
              <TextField
                id="link-input"
                label="Link"
                size="small"
                variant="outlined"
                value={imageUrl}
                disabled
                fullWidth
                onClick={() => { this.copyLinkToClipboard(imageUrl)}}
                inputProps={{ style: { fontSize: 12 }}}
              />
            </div>
          </Paper>
          <div className={classes.imageWrapper}>
            <Typography variant="h5" style={{ fontWeight: 800 }}>
              Preview
            </Typography>
            <img src={imageUrl} alt={fen} width={460} style={{ marginTop: 16 }} />
          </div>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => { this.setState({ snackbarOpen: false })}}
          message="Link copied to clipboard!"
        />
      </Container>
    );
  }
}

export default withStyles(styles)(Home);
